import get from "lodash/get";


export function getUser(state: any) {
    let obj = get(state, ["dsa", "user"]);
    if (obj) {
        return obj;
    }
    return {};
}

export function getAccountSmtpSelector(state: any) {
    return get(state, ["dsa", "accountSettings", 'smtp']) ?? {};
}

export function getAccountSmtpSavedSelector(state: any) {
    return get(state, ["dsa", "accountSettings", 'smtpSaved']) ?? {};
}

export function getCurrentUserRole(state: any) {
    let obj = get(state, ["dsa", "user", "role", "permissions"]);
    if (obj) {
        const roles: any = [];
        obj.forEach((p: any) => {
            if (p && p.name) {
                const perm: any = [];
                p.items.forEach((item: any) => {
                    if (item && item.name && item.checked) {
                        perm.push(item.name);
                    }
                });
                roles.push({ [p.name]: perm })
            }
        })
        return roles;
    }
    return [];
}

export function isAuthenticated(state: any) {
    let isAuth = get(state, ["dsa", "auth", "isAuth"]);
    if (isAuth) {
        return isAuth;
    }
    return false;
}

export function fetchUserProfileSelector(state: any) {
    let user = get(state, ["dsa", "profile", "user"]);
    if (user && Object.keys(user).length > 0) {
        return user;
    }
    return false;
}
export function fetchUserProfileAgreementSelector(state: any) {
    let user = get(state, ["dsa", "profile", "profileAgreement"]);
    if (user && Object.keys(user).length > 0) {
        return user;
    }
    return false;
}

export function fetchOnboardingUserProfileSelector(state: any) {
    let user = get(state, ["dsa", "channelQuestions", "requestUserProfile"]) || {};
    return user;
}

export function isAgreementDataSavedSuccessSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelQuestions", "isAgreementDataSaved"]) || false;
    return channelVariables;
}

export function verifyAgreementAadhaarDataSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelQuestions", "verifyAgreementAadhaarStatus"]) || false;
    return channelVariables;
}

export function aadhaarAgreementAPICallSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelQuestions", "aadhaarAgreementAPICall"]) || false;
    return channelVariables;
}

export function isPdfSentOnMailAPICallSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelQuestions", "isPdfSentOnMailAPICall"]) || false;
    return channelVariables;
}

export function fetchUserVerifyMessage(state: any) {
    let users = get(state, ["dsa", "users", "userMetaVerifyCompleted"]) || null;
    return users;
}

export function fetchUsers(state: any) {
    let users = get(state, ["dsa", "users", "users"]) || [];
    return users;
}


export function usersCount(state: any) {
    let usersCount = get(state, ["dsa", "users", "usersCount"]);
    if (usersCount) {
        return usersCount;
    }
    return null;
}

export function addUpdateSuccess(state: any) {
    let addUpdateSuccess = get(state, ["dsa", "users", "addUpdateSuccess"]);
    if (addUpdateSuccess) {
        return addUpdateSuccess;
    }
    return false;
}

export function fetchCompanyMasters(state: any) {
    let companyMasters = get(state, ["dsa", "companyMasters", "companyMasters"]) || [];
    return companyMasters;
}

export function companyMastersCount(state: any) {
    let companyMastersCount = get(state, ["dsa", "companyMasters", "companyMastersCount"]);
    if (companyMastersCount) {
        return companyMastersCount;
    }
    return null;
}

export function addUpdateCompanySuccessSelector(state: any) {
    let addUpdateCompanySuccess = get(state, ["dsa", "companyMasters", "addUpdateCompanySuccess"]);
    if (addUpdateCompanySuccess) {
        return addUpdateCompanySuccess;
    }
    return false;
}

export function fetchDSACompanyProfile(state: any) {
    let fetchDSACompanyProfile = get(state, ["dsa", "companyMasters", "companyProfile"]);
    if (fetchDSACompanyProfile && Object.keys(fetchDSACompanyProfile).length > 0) {
        return fetchDSACompanyProfile;
    }
    return false;
}

export function fetchDSAChannelProfile(state: any) {
    let profile = get(state, ["dsa", "channelMasters", "channelProfile"]);
    if (profile && Object.keys(profile).length > 0) {
        return profile;
    }
    return false;
}


export function csvImportChannelSuccessSelector(state: any) {
    let csvImportSuccess = get(state, ["dsa", "channelMasters", "csvImportSuccess"]);
    if (csvImportSuccess) {
        return csvImportSuccess;
    }
    return false;
}


export function fetchBankMasters(state: any) {
    return get(state, ["dsa", "bankMasters", 'bankMasters']) || [];
}

export function fetchBanksOfDsaActiveSelector(state: any) {
    return get(state, ["dsa", "bankMasters", "dsaBanks"]) || [];
}

// export function fetchCompanyBanksSelector(state: any) {
//     let bankMasters = get(state, ["dsa", "bankMasters"]);
//     if (bankMasters && bankMasters.companyBanks && bankMasters.companyBanks.length) {
//         return bankMasters.companyBanks;
//     }
//     return [];
// }

export function fetchAllDSABankMasters(state: any) {
    let banksOfAdmin = get(state, ["dsa", "bankMasters", "banksOfAdmin"]) || [];
    return banksOfAdmin;
}

export function bankMastersCount(state: any) {
    let bankMastersCount = get(state, ["dsa", "bankMasters", "bankMastersCount"]) || null;
    return bankMastersCount;
}
export function bankMastersDsaActiveCount(state: any) {
    let bankMastersCount = get(state, ["dsa", "bankMasters", "dsaBankCount"]) || null;
    return bankMastersCount;
}

export function addUpdateBankSuccessSelector(state: any) {
    let addUpdateBankSuccess = get(state, ["dsa", "bankMasters", "addUpdateBankSuccess"]);
    if (addUpdateBankSuccess) {
        return addUpdateBankSuccess;
    }
    return false;
}


export function fetchProductMasters(state: any) {
    let productMasters = get(state, ["dsa", "productMasters", "productMasters"]) || [];
    return productMasters;
}

export function fetchProductTypes(state: any) {
    let productMasters = get(state, ["dsa", "productMasters", "productTypes"]) || [];
    return productMasters;
}

export function productMastersCount(state: any) {
    let count = get(state, ["dsa", "productMasters", "counts"]);
    if (count) {
        return count;
    }
    return null;
}

export function csvImportSuccessSelector(state: any) {
    let csvImportSuccess = get(state, ["productMasters", "csvImportSuccess"]);
    if (csvImportSuccess) {
        return csvImportSuccess;
    }
    return false;
}

export function addUpdateProductSuccessSelector(state: any) {
    let addUpdateProductSuccess = get(state, ["productMasters", "addUpdateProductSuccess"]);
    if (addUpdateProductSuccess) {
        return addUpdateProductSuccess;
    }
    return false;
}

export function fetchChannelMasters(state: any) {
    return get(state, ["dsa", "channelMasters", "channelMasters"]) || [];
}

export function createUsernameSuccessSelector(state: any) {
    return get(state, ["dsa", "channelMasters", "createUsernameSuccess"]) || false;
}

export function fetchChannelRequests(state: any) {
    return get(state, ["dsa", "channelMasters", "channelRequests"]) || [];
}

export function fetchChannelOnBoardQuestions(state: any) {
    let channelQuestions = get(state, ["dsa", "channelQuestions", "questions"]) || [];
    return channelQuestions;
}

export function fetchQuestionsUpdateSuccess(state: any) {
    return get(state, ["dsa", "channelQuestions", 'questionsUpdateSuccess']) || false;
}

export function fetchChannelKeywords(state: any) {
    return get(state, ["dsa", "channelMasters", "channelKeywords"]) || [];
}

export function channelMastersCount(state: any) {
    return get(state, ["dsa", "channelMasters", "channelMastersCount"]) || null;
}

export function channelRequestsCount(state: any) {
    let channelRequestsCount = get(state, ["dsa", "channelMasters", "channelRequestsCount"]);
    if (channelRequestsCount) {
        return channelRequestsCount;
    }
    return null;
}

export function addUpdateChannelSuccessSelector(state: any) {
    let addUpdateChannelSuccess = get(state, ["dsa", "channelMasters", "addUpdateChannelSuccess"]);
    if (addUpdateChannelSuccess) {
        return addUpdateChannelSuccess;
    }
    return false;
}

export function verifyGstDataSelector(state: any) {
    let verifyGstStatus = get(state, ["dsa", "channelMasters", "verifyGstStatus"]);
    return verifyGstStatus;
}

export function verifyPanDataSelector(state: any) {
    let verifyPanStatus = get(state, ["dsa", "channelMasters", "verifyPanStatus"]);
    return verifyPanStatus;
}

export function verifyBankDataSelector(state: any) {
    let verifyBankStatus = get(state, ["dsa", "channelMasters", "verifyBankStatus"]);
    return verifyBankStatus;
}

export function verifyCINDataSelector(state: any) {
    let verifyCINStatus = get(state, ["dsa", "channelMasters", "verifyCINStatus"]);
    return verifyCINStatus;
}


export function fetchRolesSelector(state: any) {
    let roles = get(state, ["dsa", "roles", "roles"]) || [];
    return roles;
}

export function roleCountSelector(state: any) {
    let counts = get(state, ["dsa", "roles", "count"]) || null;
    return counts;
}
//rolePermissions

export function rolePermissions(state: any) {
    let permissions = get(state, ["dsa", "roles", "permissions", "permissions"]) || {};
    return permissions;
}

export function addRoleSuccess(state: any) {

    let success = get(state, ["dsa", "roles", "success"]);
    if (success) {
        return success;
    }
    return false;
}

export function sidebarCollapseSelector(state: any) {
    let data = get(state, ["sidebarCollapse"]);
    if (data) {
        return data;
    }
    return null;
}

export function getCurrentUserProfile(state: any) {
    let profile = get(state, ["account", 'user']);
    if (profile) {
        return profile;
    }
    return [];
}

export function fetchUserSelector(state: any) {
    let fetchDSACompanyProfile = get(state, ["dsa", "users", "userProfile"]) || [];
    return fetchDSACompanyProfile;
}

export function addUpdateApplicationSuccessSelector(state: any) {
    let addUpdateApplicationSuccess = get(state, ["dsa", "application", "addUpdateApplicationSuccess"]);
    if (addUpdateApplicationSuccess) {
        return addUpdateApplicationSuccess;
    }
    return false;
}

export function currentApplicantDataSelector(state: any) {
    let currentApplicantData = get(state, ["dsa", "application", "currentApplicantData"]);
    if (currentApplicantData) {
        return currentApplicantData;
    }
    return {};
}

export function fetchApplications(state: any) {
    let appications = get(state, ["dsa", "application", "applications"]);
    if (appications) {
        return appications;
    }
    return [];
}

export function fetchApplicationCount(state: any) {
    let count = get(state, ["dsa", "application", "applicationCount"]);
    return count;
}

export function fetchApplicationProfileSelector(state: any) {
    let applicationProfile = get(state, ["dsa", "application", "applicationProfile"]) || {};
    return applicationProfile;
}

export function getPreviewImageSelector(state: any) {
    let applicationProfile = get(state, ["dsa", "application", "documentPreview"]) || null;
    return applicationProfile;
}

export function channelVariables(state: any) {
    let channelVariables = get(state, ["dsa", "channelVariables", "variables"]) || [];
    return channelVariables;
}

export function channelVariablesCount(state: any) {
    let channelVariables = get(state, ["dsa", "channelVariables", "variableCount"]) || 0;
    return channelVariables;
}

export function channelVariablesPermissions(state: any) {
    let channelVariables = get(state, ["dsa", "channelVariables", "permissions"]) || null;
    return channelVariables;
}

export function channelVariablesSuccess(state: any) {
    let channelVariables = get(state, ["dsa", "channelVariables", "success"]) || false;
    return channelVariables;
}

export function channelVariablesFields(state: any) {
    let channelVariables = get(state, ["dsa", "channelVariables", "fields"]) || [];
    return channelVariables;
}

export function channelAgreementsSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelAgreements", "agreements"]) || [];
    return channelVariables;
}

export function channelAgreementCountSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelAgreements", "agreementsCount"]) || 0;
    return channelVariables;
}

export function channelAgreementsSuccess(state: any) {
    let channelVariables = get(state, ["dsa", "channelAgreements", "success"]) || false;
    return channelVariables;
}
export function channelAgreementsEditByIdSelector(state: any) {
    let channelVariables = get(state, ["dsa", "channelAgreements", "editAgreement"]) || false;
    return channelVariables;
}

export function isToggleThemeCustomizeSelector(state: any) {
    let channelVariables = get(state, ["dsa", "accountSettings", "isToggleThemeCustomize"]) || false;
    return channelVariables;
}

export function fetchAccountSettingsSelector(state: any) {
    let channelVariables = get(state, ["dsa", "accountSettings"]) || false;
    return channelVariables;
}
