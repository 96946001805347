import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'public',
  initialState: {
    products: [],
    subProducts: [],
    grievances: [],
    icons: []
  },
  reducers: {
    fetchAllPublicProducts(state, action) { 
      state.products = action.payload;
    },
    fetchAllPublicSubProducts(state, action) { 
      state.subProducts = action.payload;
    },
    fetchAllPublicGrievance(state, action) { 
      state.grievances = action.payload;
    },
    fetchIconsOfPublicProducts(state, action) { 
      state.icons = action.payload;
    },
  },
});

export const {fetchAllPublicProducts, fetchIconsOfPublicProducts, fetchAllPublicSubProducts, fetchAllPublicGrievance} = productSlice.actions;

export default productSlice.reducer;