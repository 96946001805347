import { createSlice } from '@reduxjs/toolkit';

const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState: {
    fields: [],
    counts: 0,
    editField: {},
    customFieldUpdateSuccess: false,
  },
  reducers: {
    fetchCustomFields(state, action) {
      state.fields = action.payload;
    },
    customFieldsCount(state, action) {
      state.counts = action.payload;
    },
    editFieldsAction(state, action) {
      state.editField = action.payload;
    },
    customFieldUpdateSuccessAction(state, action) {
      state.customFieldUpdateSuccess = action.payload;
    },
  },
});

export const { fetchCustomFields,
  editFieldsAction, customFieldsCount,
  customFieldUpdateSuccessAction
} = customFieldsSlice.actions;

export default customFieldsSlice.reducer;