
import { NavItemType } from 'types';

// assets
import {
    IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc,
    IconBuildingCommunity, IconBuildingBank, IconClipboardList,
    IconBook, IconGizmo, IconUserPlus, IconUsers, IconLanguage, IconClipboard, IconCategory, IconCategory2,
    IconCategoryMinus
} from '@tabler/icons-react';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook,
    IconCategory, IconCategory2,
    IconGizmo,
    IconUserPlus, IconCategoryMinus,
    IconUsers,
    IconLanguage, IconClipboard
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application: NavItemType = {
    id: 'admin',
    title: 'Admin',
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'menus',
            title: 'Menus',
            type: 'collapse',
            icon: icons.IconBook,
            selected: ['menus', 'main-menu', 'footer-menu'],
            children: [
                {
                    id: 'footer-menu',
                    title: 'Footer menu',
                    type: 'item',
                    url: '/menus/footer-menu'

                }
            ]
        },
        {
            id: 'posts',
            title: 'Posts',
            type: 'collapse',
            selected: ['posts', 'edit', 'posts-list'],
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'category',
                    title: 'Category List',
                    type: 'item',
                    url: '/posts/category'
                },
                {
                    id: 'create',
                    title: 'Create Post',
                    type: 'item',
                    url: '/posts/create'
                },
                {
                    id: 'list',
                    title: 'Posts',
                    type: 'item',
                    url: '/posts/list'

                },
            ]
        },
        {
            id: 'pages',
            title: 'Pages',
            type: 'collapse',
            icon: icons.IconClipboard,
            children: [
                {
                    id: 'home-page',
                    title: 'Home Page',
                    type: 'item',
                    url: '/pages/static/home'
                },
                {
                    id: 'contact-page',
                    title: 'Contact Page',
                    type: 'item',
                    url: '/pages/static/contact'
                },
                {
                    id: 'about-page',
                    title: 'About Page',
                    type: 'item',
                    url: '/pages/static/about'
                },
                {
                    id: 'services-page',
                    title: 'Services Page',
                    type: 'item',
                    url: '/pages/static/services'
                },
            ]
        },
        {
            id: 'keywords',
            title: 'Keywords',
            type: 'collapse',
            icon: icons.IconBook,
            selected: ['keywords', 'edit'],
            children: [
                {
                    id: 'create',
                    title: 'Add Keyword',
                    type: 'item',
                    url: '/keywords/create'
                },
                {
                    id: 'list',
                    title: 'Keywords List',
                    type: 'item',
                    url: '/keywords/list'
                },
            ]
        },
        {
            id: 'product',
            title: 'Product',
            type: 'collapse',
            icon: icons.IconCategory,
            selected: ['product', 'edit'],
            children: [
                {
                    id: 'add',
                    title: 'Add Product',
                    type: 'item',
                    url: '/product/add'
                },
                {
                    id: 'list',
                    title: 'Product List',
                    type: 'item',
                    url: '/product/list'
                },
            ]
        },
        {
            id: 'product-type',
            title: 'Product Types',
            type: 'collapse',
            icon: icons.IconCategory2,
            selected: ['product-types', 'edit'],
            children: [
                {
                    id: 'add',
                    title: 'Product Types Add',
                    type: 'item',
                    url: '/product-types/add'

                },
                {
                    id: 'list',
                    title: 'Product Types List',
                    type: 'item',
                    url: '/product-types/list'

                },
            ]
        },
        {
            id: 'sub-product',
            title: 'Grievances',
            type: 'collapse',
            icon: icons.IconCategoryMinus,
            selected: ['sub-product', 'edit'],
            children: [
                {
                    id: 'add',
                    title: 'Add Grievance',
                    type: 'item',
                    url: '/sub-product/add'
                },
                {
                    id: 'list',
                    title: 'Grievance List',
                    type: 'item',
                    url: '/sub-product/list'
                },
            ]
        },
        {
            id: 'flow-builder',
            title: 'Flow Builder',
            type: 'collapse',
            icon: icons.IconCategoryMinus,
            selected: ['flow-builder', 'edit', 'question', 'questions'],
            children: [
                {
                    id: 'add',
                    title: 'Add Flow Builder',
                    type: 'item',
                    url: '/flow-builder/add'
                },
                {
                    id: 'list',
                    title: 'Flow Builder List',
                    type: 'item',
                    url: '/flow-builder/list'
                },
                {
                    id: 'custom-fields',
                    title: 'Custom Fields',
                    type: 'item',
                    url: '/flow-builder/custom-fields'

                },
                {
                    id: 'questions',
                    title: 'Questions List',
                    type: 'item',
                    url: '/flow-builder/questions'
                },

            ]
        },
        {
            id: 'case',
            title: 'Complaint Case',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'create-case',
                    title: 'Create Case',
                    type: 'item',
                    url: '/complaint/create'
                },
                {
                    id: 'list-case',
                    title: 'Case List',
                    type: 'item',
                    url: '/complaint/list'

                },
            ]
        },
        {
            id: 'mailTemplates',
            title: 'Mail Templates',
            type: 'collapse',
            selected: ['mail-templates'],
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'create-template',
                    title: 'Create Template',
                    type: 'item',
                    url: '/mail-templates/create'
                },
                {
                    id: 'list-mail-template',
                    title: 'Templates List',
                    type: 'item',
                    url: '/mail-templates/list'

                },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'add-user',
                    title: 'Add User',
                    type: 'item',
                    url: '/user/add'
                },
                {
                    id: 'list-user',
                    title: 'User List',
                    type: 'item',
                    url: '/user/list'
                },
                {
                    id: 'roles-permissiosn',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-role',
                            title: 'Add Role',
                            type: 'item',
                            url: '/user/role/add'
                        },
                        {
                            id: 'list-role',
                            title: 'Role List',
                            type: 'item',
                            url: '/user/role/list'
                        },
                    ]
                }

            ]
        },

        // {
        //     id: 'Home',
        //     title: 'HOME',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'individual-profile',
        //             title: 'Individual Profile',
        //             type: 'item',
        //             url: '/user/profile'
        //         },
        //         {
        //             id: 'company-profile',
        //             title: 'Company Profile',
        //             type: 'item',
        //             url: '/company/profile'
        //         },
        //         {
        //             id: 'manage-white-listing',
        //             title: 'Manage white listing',
        //             type: 'item',
        //             url: '/company'
        //         },
        //         {
        //             id: 'profile-password',
        //             title: 'Change Password',
        //             type: 'item',
        //             url: '/company/profile'
        //         },
        //         {
        //             id: 'login-profile',
        //             title: 'Login Details',
        //             type: 'item',
        //             url: '/company/profile'
        //         }   
        //     ]
        // },
        // {
        //     id: 'APPLICATION',
        //     title: 'APPLICATION',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'List-Applications',
        //             title: 'List Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'View-Applications',
        //             title: 'View Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'Pending-Applications',
        //             title: 'Pending Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'Completed-Applications',
        //             title: 'Completed Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'Search-Applications',
        //             title: 'Search Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //     ]
        // },
        // {
        //     id: 'manage-roles',
        //     title: 'MANAGE ROLES',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-agency-user',
        //             title: 'Add Agency User',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'agency-user-list',
        //             title: 'Agency user list',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'manage-agency-user',
        //             title: 'Manage Agency User',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-new-role',
        //             title: 'Add New Role',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'assign-permissions',
        //             title: 'Assign Permissions',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'access-role',
        //             title: 'Access role',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'role-list',
        //             title: 'Role List',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'team-login-details',
        //             title: 'Team Login Details',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'attendance',
        //             title: 'Attendance',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'live-team-tracking',
        //             title: 'Live Team Tracking',
        //             type: 'item',
        //             url: '/user/'
        //         },

        //     ]
        // },
        // {
        //     id: 'companies',
        //     title: 'COMPANIES',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'company-master',
        //             title: 'Company Master',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-company-name',
        //             title: 'Add Company Name',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-company',
        //             title: 'List Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'active-company',
        //             title: 'Active Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'disabled-company',
        //             title: 'Disabled Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'permanent-delete-company',
        //             title: 'Permanent Delete Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-company-user',
        //             title: 'Add Company User',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-lompany-executive',
        //             title: 'List Company Executive',
        //             type: 'item',
        //             url: '/user/'
        //         }

        //     ]
        // },

        // {
        //     id: 'dedupe',
        //     title: 'DEDUPE',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-duplicate-data',
        //             title: 'Add Duplicate Data',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'check-duplicate',
        //             title: 'Check Duplicate',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'adddefaulter-ata',
        //             title: 'Add Defaulter Data',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'check-defaulter',
        //             title: 'Check Defaulter',
        //             type: 'item',
        //             url: '/user/'
        //         } 
        //     ]
        // },
        // {
        //     id: 'broadcast',
        //     title: 'BROADCAST',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-Manage-ontact',
        //             title: 'Add + Manage Contact',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-manage-group',
        //             title: 'Add + Manage Group',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-manage-templates',
        //             title: 'Add + Manage Templates',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-manage-reccuring-alerts',
        //             title: 'Add + Manage Reccuring Alerts',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'import-contacts',
        //             title: 'Import Contacts',
        //             type: 'item',
        //             url: '/user/'
        //         } ,
        //         {
        //             id: 'schedule-instant-alert',
        //             title: 'Schedule + Instant Alert',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'all-delivery-report',
        //             title: 'All Delivery Report',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //     ]
        // },
        // {
        //     id: 'system-alerts',
        //     title: 'SYSTEM ALERT & BALANCES',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'google-api-setting',
        //             title: 'Google API setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'whatsApp-setting',
        //             title: 'SMS/Mail /Voice/WhatsApp Setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'kyc-api-settings',
        //             title: 'KYC API settings',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'state-n-analytics',
        //     title: 'STATS n ANALYTICS',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'company-wise',
        //             title: 'Company Wise',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'user-wise',
        //             title: 'User Wise',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'application-wise',
        //             title: 'Application Wise',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'tat-wise',
        //             title: 'TAT Wise',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'adult-footprint',
        //     title: 'AUDIT FOOTPRINT',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'ip-tracker',
        //             title: 'IP Tracker',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'audit-log',
        //             title: 'Audit log',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'new-notification',
        //     title: 'NEWS & NOTIFICATION',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-news',
        //             title: 'Add News',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-news',
        //             title: 'List News',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'sound-manager',
        //             title: 'Sound Manager',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-sound-manager',
        //             title: 'List Sound Manager',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'app-configuration',
        //     title: 'APP CONFIGURATION',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'theme-setting',
        //             title: 'Theme setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'app-setting',
        //             title: 'App Setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'alertsnotification-setting',
        //             title: 'Alerts & Notification Setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'database-exp',
        //             title: 'Database - Exp/Dwnld/Purge',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'billing',
        //     title: 'BILLING',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'company-billing',
        //             title: 'Company Billing',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'executive-billing',
        //             title: 'Executive Billing',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },

    ]
};

export default application;





